@import "../helpers/variables";
@import "../helpers/mixins";

.personal-area {
  padding: 40px 0;
  text-align: center;

  &__items {
    display: flex;
    justify-content: center;

    @include media($md) {
      flex-direction: column;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 24px;

      @include media($md) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    a {
      display: inline-block;
      padding: 18px 40px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      color: #252525;
      background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
      background-size: 400% 100%;
      transition: background-size 0.3s;
      cursor: pointer;
      @include media($md) {
        width: 100%;
      }
      &:hover {
        box-shadow: 0 4px 10px rgba(148, 124, 4, 0.32);
        background-size: 100% 100%;
      }

      &:active {
        box-shadow: inset 0 0 4px #947c04;
      }
    }
  }
}